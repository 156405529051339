.ilu-4 .anim-circle {
  transform: rotateZ(148deg);
  transform-origin: center center;
  animation: circling-4 4000ms ease-in-out infinite alternate;
}

.buble-1 {
  transform: translate(4%, 0);
  transform-origin: center center;
  animation: moving-4 2000ms ease-in-out infinite;
}

.buble-2 {
  transform: translate(4%, 0);
  transform-origin: center center;
  animation: moving-4 2000ms 1000ms ease-in-out reverse infinite;
}

@keyframes circling-4 {
  0% {
    transform: rotateZ(148deg);
  }
  100% {
    transform: rotateZ(8deg);
  }
}

@keyframes moving-4 {
  0% {
    transform: translate(4%, 0);
  }
  50% {
    transform: translate(-2%, 0);
  }
  100% {
    transform: translate(4%, 0);
  }
}
