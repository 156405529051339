.bf-team-card {
  position: relative;
  height: auto;
  background-color: var(--card-bg-color, #9b8464);
  border-radius: 24px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 211px; /* Fila para la imagen y fila para el contenido */
  grid-template-areas:
    "photo"
    "content";
}

.slick-slide .bf-team-card {
  margin-inline: 12px;
  cursor: grab;
}

.bf-team-card-instagram-logo-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background-color: var(--instagram-logo-bg-color, rgba(0, 0, 0, 0.48));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.bf-team-card-instagram-logo {
  width: 20px;
  height: 20px;
}

.bf-team-card-photo {
  grid-area: photo;
  display: block;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 15/16;
  border-radius: 24px;
  z-index: 1;
  object-fit: cover;
}

.bf-team-card-content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px 20px 20px;
}

.bf-team-card.overlay-active .bf-team-card-content {
  background-color: var(--overlay-color, rgba(0, 0, 0, 0.7)); /* Fondo oscuro */
  grid-row-start: photo;
  z-index: 100;
}

.bf-team-card-name {
  font-family: "Recline Medium";
  font-size: 32px;
  color: var(--card-text-color, #f9fafa);
  margin: 0;
}

.bf-team-card-role {
  font-family: "Montserrat Bold";
  font-size: 20px;
  line-height: 20px;
  color: var(--card-text-color, #f9fafa);
  margin: 0;
}

.bf-team-card-description {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 19.2px;
  color: var(--card-text-color, #f9fafa);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.bf-team-card-description p {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.bf-team-card.overlay-active .bf-team-card-description {
  -webkit-line-clamp: unset;
  overflow: visible;
  text-overflow: unset;
}

.bf-team-card-button {
  width: 100%;
  background-color: transparent;
  font-family: "Montserrat Bold";
  font-size: 13px;
  line-height: 15.6px;
  text-align: center;
  color: var(--card-text-color, #f9fafa);
  cursor: pointer;
  padding: 8px 16px;
  z-index: 5;
  margin-top: auto;
}
