.bf-hero-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 64px;
}

.header + main > .bf-hero-container {
  padding-top: 64px;
}

.bf-hero-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color, rgba(0, 0, 0, 0.7));
  z-index: 0;
}

.bf-hero-marquee-wrapper {
  width: 100%;
  background-color: rgba(10, 13, 11, 1);
  display: flex;
  justify-content: center;
  flex-grow: 0;
}

.bf-hero-logo-container {
  width: 100%;
  max-width: 1280px;
  height: 40px;
  padding-block: 2px;
  padding-inline: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  z-index: 1;
}

.bf-hero-content {
  width: 100%;
  max-width: 1280px;
  padding-block: 2px;
  padding-inline: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-grow: 1;
  z-index: 1;
}

.bf-hero-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.bf-hero-title,
.bf-hero-title-subtitle {
  color: rgba(249, 250, 250, 1);
}

.bf-hero-title {
  font-family: "Montserrat Bold";
  font-size: 64px;
  line-height: 51.2px;
}
.bf-hero-title-subtitle {
  font-family: "Recline Medium";
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
}

@media (min-width: 600px) {
  .bf-hero-logo-container {
    height: 64px;
    padding-block: 8px;
    padding-inline: 64px;
  }

  .bf-hero-content {
    padding-inline: 64px;
    gap: 40px;
  }

  .bf-hero-content .bf-cta {
    width: 457px;
  }

  .bf-hero-title {
    font-size: 80px;
    line-height: 64px;
  }

  .bf-hero-title-subtitle {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (width > 1000px) {
  .bf-hero-logo-container {
    height: 80px;
    padding-block: 12px;
    padding-inline: 80px;
  }

  .bf-hero-content {
    padding-inline: 80px;
    gap: 40px;
  }

  .bf-hero-content .bf-cta {
    width: 489px;
  }

  .bf-hero-title {
    font-size: 128px;
    line-height: 102.4px;
  }

  .bf-hero-title-subtitle {
    font-size: 72px;
    line-height: 72px;
  }
}
