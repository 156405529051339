.bf-cta {
  width: 100%;
  padding: 15px 24px;
  font-family: "Montserrat SemiBold";
  font-size: 18px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: var(--cta-text-color, #f9fafa);
  border-radius: var(--cta-border-radius, 100px);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bf-cta:hover {
  opacity: 0.76;
}

.bf-cta-solid {
  background-color: var(--cta-bg-color, #cf9758);
  border: none;
}

.bf-cta-outline {
  background-color: transparent;
  border: 2px solid var(--cta-border-color, #f9fafa);
}

@media (width > 600px) {
  .bf-cta {
    padding: 21px 40px;
  }
}

@media (width > 1000px) {
  .bf-cta-solid {
    padding: 24px 80px;
  }
}
