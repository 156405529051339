.process-container {
  position: relative;
  padding-block: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  text-align: center;
  background: url(../../../../../assets/img/bg_producto.png) no-repeat top left;
  background-size: 100% 100%;
}

.process-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.process-item .illustration {
  width: 120px;
  height: 120px;
  flex: 0 0 auto;
}

.process-item-title-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.process-item-number {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Montserrat Bold";
  font-size: 80px;
  line-height: 0.85;
  color: var(--section-color, #f9fafa);
  opacity: 0.32;
  z-index: 0;
}

.process-item-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
  color: var(--section-color, #f9fafa);
}

.process-item-title {
  font-family: "Squada One";
  font-size: 32px;
  line-height: 0.85;
}

.process-item-description {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 1.2;
  max-width: 480px;
}

@media (width > 600px) {
  .process-container {
    padding-block: 64px;
    padding-left: 56px;
    gap: 64px;
    background-size: 65% 100%;
    align-items: flex-start;
  }

  .process-item {
    flex-direction: row;
    gap: 40px;
  }

  .process-item .illustration {
    width: 164px;
    height: 164px;
  }

  .process-item-title-wrapper {
    text-align: left;
  }

  .process-item-number {
    top: -25px;
    left: 2px;
    font-size: 6rem;
  }

  .process-item-title {
    font-size: 3rem;
  }

  .process-item-description {
    font-size: 18px;
    max-width: none;
  }
}

@media (width > 62.5rem) {
  .process-container {
    max-width: 800px;
    padding-block: 5rem;
    gap: 80px;
  }

  .process-item .illustration {
    width: 180px;
    height: 180px;
  }

  .process-item-number {
    top: -30px;
    font-size: 7.5rem;
  }

  .process-item-title {
    font-size: 56px;
  }
}
