@import url("https://fonts.googleapis.com/css2?family=Squada+One&display=swap");
.geopol-products {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 56px 20px 64px;
  background: linear-gradient(180deg, #00234c 0%, #003473 100%);
}

.geopol-products-content {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.geopol-products-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--section-color, #f9fafa);
}

.geopol-products-title {
  font-family: "Squada One";
  font-weight: 400;
  font-size: 52px;
  line-height: 0.85;
}

.geopol-products-subtitle {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 1.2;
}

.geopol-products-pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: inherit;
}
.geopol-products-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: inherit;
}

@media (width > 600px) {
  .geopol-products {
    padding: 80px 80px 96px;
  }

  .geopol-products-content {
    gap: 40px;
  }

  .geopol-products-title-wrapper {
    gap: 40px;
  }

  .geopol-products-title {
    font-size: 80px;
    line-height: 64px;
  }

  .geopol-products-subtitle {
    font-size: 24px;
    line-height: 28.8px;
  }
}

@media (width > 1000px) {
  .geopol-products {
    padding: 56px 80px 64px;
    align-items: center;
  }

  .geopol-products-content {
    max-width: 1280px;
  }

  .geopol-products-title-wrapper {
    max-width: 800px;
    align-self: flex-start;
    gap: 24px;
  }

  .geopol-products-title {
    font-size: 96px;
    line-height: 72px;
  }

  .geopol-products-pricing {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 120px;
  }

  .card-product {
    position: sticky;
    top: 80px;
    max-width: 360px;
  }

  .geopol-products-details {
    max-width: 800px;
    gap: 40px;
  }
}
