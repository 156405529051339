.bf-bg {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
}

.bg-img {
  top: 0;
  object-position: top center;
  display: none;
}

.bg-img-portrait {
  display: block;
}

@media (width > 600px) {
  .bf-video {
    top: 64px;
    height: calc(100% - 64px);
  }

  .bg-img-portrait {
    display: none;
  }

  .bg-img-landscape {
    display: block;
  }
}

@media (width > 1000px) {
  .bf-video {
    top: 80px;
    height: calc(100% - 80px);
  }
}
