.bf-marquee-container {
  max-width: 100%;
  overflow: hidden;
  height: 40px;
  background-color: transparent;
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 24px;
  color: rgba(207, 151, 88, 1);
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 1;
}

.bf-marquee-text-scrolling {
  display: inline-block;
  padding-inline: 8px;
  animation: bfScroll 10s linear infinite;
  will-change: transform;
}
.bf-marquee-text-scrolling::after {
  content: "•";
  margin-left: 16px;
}

@keyframes bfScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (width > 600px) {
  .bf-marquee-container {
    height: 64px;
    font-size: 24px;
    line-height: 28.8px;
  }
}

@media (width > 1000px) {
  .bf-marquee-container {
    height: 80px;

    font-size: 28px;
    line-height: 34px;
  }
}
