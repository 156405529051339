.ilu-1 .anim-circle {
  transform: rotateZ(-45deg);
  transform-origin: center center;
  animation: circling-1 4000ms ease-in-out infinite alternate;
}
.ilu-1 .device {
  transform: translate(0, 1%);
  transform-origin: center center;
  animation: moving-1 2000ms ease-in-out infinite alternate;
}

.ilu-1 .line-anim {
  stroke-dasharray: 32px;
  stroke-dashoffset: 32px;
  animation: stroke-animate-1 ease-out 800ms 1000ms 1 forwards;
}

/* Rotate circle */
@keyframes circling-1 {
  0% {
    transform: rotateZ(-45deg);
  }
  100% {
    transform: rotateZ(85deg);
  }
}

/* Traslate device */
@keyframes moving-1 {
  0% {
    transform: translate(0, 1%) rotateZ(0);
  }
  50% {
    transform: translate(0, 2%) rotateZ(2deg);
  }
  100% {
    transform: translate(0, 0%) rotateZ(-2deg);
  }
}

/* Stroke animation */
@keyframes stroke-animate-1 {
  from {
    stroke-dashoffset: 32px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}
