/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Squada+One&display=swap");

/* HEADER - Menu Desktop */
.colored-header {
  background: linear-gradient(90deg, #14233a 0%, rgba(37, 42, 32, 0.7) 100%);
  background-color: transparent;
}

.colored-header img {
  filter: brightness(10);
  background: transparent;
}

.colored-header .submenu {
  background-color: #10252e;
}

.colored-header a {
  color: #ffffff;
}

/* HEADER - Menu Mobile */

.colored-mobile-menu {
  background-color: transparent;
}
.colored-mobile-menu button {
  background-color: transparent;
}

.colored-mobile-menu .hamburger-line {
  background-color: #ffffff;
}

.colored-mobile-menu .mobile-ul {
  background-color: #10252e;
  box-shadow: 4px 4px 4px 0px #00000040;
}

.colored-mobile-menu .mobile-ul .a {
  color: #ffffff;
}

.colored-mobile-menu .mobile-ul .a.active {
  border-bottom: 1px solid #ffffff;
}

.colored-mobile-menu .submenu-mobile .a {
  color: #ffffff;
}

.colored-mobile-menu .divider-submenu {
  border-bottom: 1px solid #e5e5e533;
}

/* LANDING */

.geopol-landing {
  --cta-border-radius: 1rem;
  --faq-bg-color: #cce0f9;
  --faq-color: #10252e;
  --faq-border-color: #cce0f9;
  --card-bg-color: #e5eef9;
  --card-text-color: #10252e;
}

/* HERO */
.geopol-landing .bf-hero-container {
  --cta-bg-color: #bad0a2;
  --cta-text-color: #4a5341;
  --overlay-color: #003473ad;
}

.geopol-landing .bf-bg {
  top: 0;
  width: 100vw;
  height: 100vh;
}

.geopol-landing .bg-img-landscape {
  display: none;
}

.geopol-landing .bg-img-portrait {
  display: block;
}

.geopol-landing .bf-hero-content {
  gap: 56px;
  margin-top: 64px;
}

.geopol-landing .bf-hero-title-wrapper {
  gap: 16px;
}

.brand-logo {
  width: 277px;
}

.geopol-landing .bf-hero-title {
  font-family: "Squada One";
  font-weight: 400;
  font-size: 72px;
  line-height: 0.85;
}

.geopol-landing .bf-hero-title-subtitle {
  font-size: 32px;
}

.geopol-landing .bf-hero-content button {
  width: fit-content;
}

/* PRODUCTS */
.geopol-landing .geopol-products {
  --section-color: #f9fafa;
  --card-bg-color: #ffffff;
  --card-text-color: #10252e;
  --cta-bg-color: #0057bf;
  --cta-text-color: #f9fafa;
}

.geopol-landing .geopol-products .bf-faq-item {
  --faq-bg-color: #e5eef9;
}

.geopol-landing .geopol-products .bf-faq-answer-wrapper {
  border: none;
}

/* TEAM */
.geopol-landing .bf-team {
  --section-bg-color: #003473;
  --section-color: #f9fafa;
  --overlay-color: #f9fafa;
}

.geopol-landing .bf-team-title {
  font-family: "Squada One";
  font-size: 52px;
  font-weight: 400;
}

.geopol-landing .bf-team-subtitle {
  font-size: 20px;
  line-height: 1.2;
}

.geopol-team-card .bf-team-card {
  --instagram-logo-bg-color: #003473ad;
  --overlay-color: #e5eef9;
}

/* FAQ */
.geopol-landing .bf-faq {
  --section-bg-color: #f9fafa;
  --section-color: #252a20;
  --cta-text-color: #0057bf;
  --cta-border-color: #0057bf;
}

.geopol-landing .bf-faq-title-wrapper {
  gap: 16px;
}

.geopol-landing .bf-faq-title {
  font-family: "Squada One";
  font-size: 52px;
  font-weight: 400;
}

.geopol-landing .bf-faq-title-subtitle {
  font-size: 20px;
  line-height: 1.2;
}

.geopol-landing .bf-faq-answer-wrapper li {
  margin-left: 16px;
}

/* MEDIUM QUERY */
@media (width > 600px) {
  .geopol-landing .bg-img-landscape {
    display: block;
  }

  .geopol.landing .bg-img-portrait {
    display: none;
  }

  .geopol-landing .bf-hero-content {
    gap: 64px;
  }

  .geopol-landing .bf-hero-title-wrapper {
    gap: 20px;
  }

  .brand-logo {
    width: 414px;
  }

  .geopol-landing .bf-hero-title {
    font-size: 120px;
  }

  .geopol-landing .bf-hero-title-subtitle {
    font-size: 48px;
  }

  .geopol-landing .bf-team-title {
    font-size: 80px;
  }

  .geopol-landing .bf-team-subtitle {
    font-size: 24px;
  }

  .geopol-landing .bf-faq-title {
    font-size: 80px;
  }

  .geopol-landing .bf-faq-title-subtitle {
    font-size: 24px;
  }

  .geopol-landing .bf-faq-question {
    border: none;
  }

  .geopol-landing .bf-faq-answer-wrapper {
    border: none;
  }

  .bf-faq-button-wrapper {
    padding-top: 16px;
  }
}

/* LARGE QUERY */
@media (width > 1000px) {
  .geopol-landing .bf-hero-content {
    gap: 80px;
  }

  .geopol-landing .bf-hero-title {
    font-size: 180px;
  }

  /* .brand-logo {
    width: 320px;
  } */

  .geopol-landing .bf-hero-title-subtitle {
    font-size: 56px;
  }

  .geopol-landing .bf-team-title {
    font-size: 96px;
  }

  .geopol-landing .bf-faq-title {
    font-size: 96px;
  }
}
