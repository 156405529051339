.bf-faq {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: var(--section-bg-color, #291e12);
  padding: 56px 20px 64px;
}

.bf-faq-container {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.bf-faq-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bf-faq-title {
  font-family: "Recline Medium";
  font-size: 42px;
  line-height: 42px;
  color: var(--section-color, #f9fafa);
}

.bf-faq-title-subtitle {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 24px;
  color: var(--section-color, #f9fafa);
}

.bf-faq-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bf-faq-item {
  background-color: var(--faq-bg-color, #c3b5a2);
  color: var(--faq-color, #141a16);
  border-radius: var(--cta-border-radius, none);
  border: 1px solid var(--faq-border-color, #9b8464);
}

.bf-faq-item .arrow-icon path {
  fill: var(--faq-color, #141a16);
}

.bf-faq-question {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat Bold";
  font-size: 16px;
  line-height: 19.2px;
  padding: 16px;
  gap: 16px;
  cursor: pointer;
}

.bf-faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 1.2;
  /* will-change: max-height; */
}

.bf-faq-answer-wrapper {
  padding: 16px 16px 20px;
  border: 1px solid var(--faq-border-color, #9b8464);
  margin: 0;
}

.bf-faq-answer-wrapper p {
  margin: 0;
  font-size: inherit;
}
.bf-faq-answer-wrapper p + p {
  margin-top: 0.8em;
}

.bf-faq-answer-wrapper strong {
  font-family: "Montserrat Bold";
}

.bf-faq-answer-wrapper ul {
  margin: 20px;
}

.bf-faq-answer-wrapper li + li {
  margin-top: 0.4em;
}

.bf-faq-answer.open {
  max-height: 100vh;
}

.arrow-icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.arrow-icon.rotate {
  transform: rotate(-45deg);
}

.bf-faq-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (width > 600px) {
  .bf-faq {
    padding: 80px 80px 96px;
  }

  .bf-faq-title {
    font-size: 64px;
    line-height: 64px;
  }

  .bf-faq-title-subtitle {
    font-size: 24px;
    line-height: 28.8px;
  }

  .bf-faq-question,
  .bf-faq-answer {
    font-size: 18px;
    line-height: 1.35;
  }

  .bf-faq-button-wrapper {
    width: auto;
  }
}

@media (width > 1000px) {
  .bf-faq-title {
    font-size: 72px;
    line-height: 72px;
  }
}
