.bf-team {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 56px 20px 64px;
  background-color: var(--section-bg-color, rgba(10, 13, 11, 1));
  max-width: none;
  min-height: auto;
  color: initial;
  border: none;
  border-radius: 0;
}

.bf-team-wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
  color: var(--section-color, #f9fafa);
}

.bf-team-title {
  font-family: "Recline Medium";
  font-size: 42px;
  line-height: 42px;
}

.bf-team-subtitle {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 19.2px;
}

.bf-team-slider {
  width: 100%;
  padding-bottom: 60px;
}

.bf-team-slider .slick-list {
  overflow: visible;
}

.bf-team-slider .slick-prev,
.bf-team-slider .slick-next {
  z-index: 500;
  bottom: -40px;
  top: auto;
  left: 50%;
  right: auto;
  margin: 0;
  width: 60px;
  height: 60px;
}
.bf-team-slider .slick-prev {
  transform: translate(-150%, 100%);
}
.bf-team-slider .slick-next {
  transform: translate(50%, 100%);
}

.bf-team-slider .slick-prev:before,
.bf-team-slider .slick-next:before {
  font-size: 60px;
}

@media (width > 600px) {
  .bf-team {
    padding: 80px 80px 96px;
  }

  .bf-team-title-wrapper {
    gap: 16px;
  }

  .bf-team-title {
    font-size: 64px;
    line-height: 64px;
  }

  .bf-team-subtitle {
    font-size: 18px;
    line-height: 21.6px;
  }
}

@media (width > 1000px) {
  .bf-team-title {
    font-size: 72px;
    line-height: 72px;
  }
}

@media (width > 2000px) {
  .bf-team-slider .slick-list::after,
  .bf-team-slider .slick-list::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50vw;
    z-index: 100;
    pointer-events: none;
  }
  .bf-team-slider .slick-list::after {
    right: 0;
    transform: translateX(100%);
    background: linear-gradient(-90deg, rgba(10, 13, 11, 1) 60%, rgba(255, 255, 255, 0) 100%);
  }
  .bf-team-slider .slick-list::before {
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, rgba(10, 13, 11, 1) 60%, rgba(255, 255, 255, 0) 100%);
  }
}
