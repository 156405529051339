.card-product {
  height: fit-content;
  border-radius: 16px;
  background-color: var(--card-bg-color, #e5eef9);
}

.card-product-content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.card-product-price-content {
  display: flex;
  flex-direction: column;
  color: var(--card-text-color, #10252e);
}

.card-product-price-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.card-product-price {
  font-family: "Montserrat Bold";
  font-size: 52px;
  line-height: 1;
}

.card-product-currency {
  font-family: "Montserrat SemiBold";
  font-size: 28px;
}

.card-product-tax {
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 1.2;
}

.card-product-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card-product-description {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 1.2;
  color: #10252e;
}

.card-product-info-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.card-productinfo-icon {
  align-self: flex-start;
  width: 24px;
  height: auto;
}

.card-product-info-text {
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 1.2;
  color: #73858c;
}

.card-product-info-text a {
  font-family: "Montserrat Bold";
  color: var(--cta-bg-color, #0057bf);
}

@media (width > 600px) {
  .card-product-content {
    padding: 40px;
    gap: 40px;
  }
  .card-product-info-wrapper {
    font-size: 16px;
  }

  .card-product-price {
    font-size: 80px;
  }

  .card-product-currency {
    font-size: 32px;
  }

  .card-product-tax {
    font-size: 16px;
  }

  .card-product-description {
    font-size: 18px;
  }

  .card-product-info-text {
    font-size: 16px;
  }

  .card-product button {
    width: 100%;
  }
}

@media (width > 1000px) {
  .card-product {
    align-self: flex-start;
  }

  .card-product-content {
    gap: 24px;
  }

  .card-product-price {
    font-size: 96px;
  }
}
