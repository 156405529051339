.bf-info-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 80px 48px;
  background-color: rgba(10, 13, 11, 1);
  text-align: center;

  max-width: none;
  min-height: auto;
  color: initial;
  border: none;
  border-radius: 0;
}

.bf-info-bold-text,
.bf-info-text {
  max-width: 1280px;
  font-size: 24px;
  line-height: 28.8px;
  color: rgba(241, 246, 236, 1);
  margin: 0;
}

.bf-info-bold-text {
  font-family: "Montserrat SemiBold";
}

.bf-info-text {
  font-family: "Montserrat";
}

@media (width > 600px) {
  .bf-info-wrapper {
    padding: 56px 64px 64px;
  }
}

@media (width > 1000px) {
  .bf-info-wrapper {
    padding: 80px 80px 96px;
  }
}
