.ilu-3 .anim-circle {
  transform: rotateZ(-25deg);
  transform-origin: center center;
  animation: circling-1 4000ms ease-in-out infinite alternate;
}
.ilu-3 .device {
  transform: translate(0, -8%) rotateZ(-2deg);
  transform-origin: center center;
  animation: moving-3 1400ms ease-in-out infinite alternate;
}
/* Traslate device */
@keyframes moving-3 {
  0% {
    transform: translate(0, -8%) rotateZ(-2deg);
  }
  100% {
    transform: translate(0, -1%) rotateZ(0deg);
  }
}
