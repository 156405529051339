@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  scroll-padding-top: 80px;
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

section {
  width: 100%;
  max-width: 420px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 4rem;
  background-color: #ffffff;
  color: #29292e;
  border: 1px solid #bad0a2;
  border-radius: 0.5rem;
}

a,
a:visited {
  color: #fff;
}

button,
textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 0.25rem;
  background-color: #e5e5e5;
  border-width: 0;
  line-height: 1.5rem;
}

label,
button {
  margin-top: 1rem;

  font-weight: 600;
}

button {
  padding: 0.5rem;
  background-color: #bad0a2;
}

.instructions {
  font-size: 10px;
  border-radius: 0.5rem;
  background: #29292e;
  color: #fff;
  padding: 0.25rem;
}

@media screen and (max-width: 768px) {
  .instructions {
    font-size: 10px;
  }

  .instructions-password {
    font-size: 10px;
    margin-top: -3.15rem !important;
  }
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.logo {
  height: 60px;
  width: 180px;
}

.centered {
  text-align: center;
}

.centered1 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 20px;
}

.icon {
  margin-left: 10px;
}
