.ilu-2 .anim-circle {
  transform: rotateZ(20deg);
  transform-origin: center center;
  animation: circling-2 4000ms ease-in-out infinite alternate;
}
.ilu-2 .line-anim {
  stroke-dasharray: 95px;
  stroke-dashoffset: 95px;
  animation: stroke-animate-21 ease-in-out 2000ms infinite, delay-animation 4000ms linear infinite;
}
.line-anim.a2 {
  animation: stroke-animate-22 ease-in-out 2000ms infinite, delay-animation 4000ms linear infinite;
}
.line-anim.a3 {
  animation: stroke-animate-23 ease-in-out 2000ms infinite, delay-animation 4000ms linear infinite;
}
.line-anim.a4 {
  animation: stroke-animate-24 ease-in-out 2000ms infinite, delay-animation 4000ms linear infinite;
}
.line-anim.a5 {
  animation: stroke-animate-25 linear 2000ms infinite, delay-animation 4000ms linear infinite;
}

@keyframes stroke-animate-21 {
  0% {
    stroke-dashoffset: 95px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes stroke-animate-22 {
  20% {
    stroke-dashoffset: 95px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes stroke-animate-23 {
  40% {
    stroke-dashoffset: 95px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes stroke-animate-24 {
  60% {
    stroke-dashoffset: 95px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes stroke-animate-25 {
  80% {
    stroke-dashoffset: 95px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes delay-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes circling-2 {
  0% {
    transform: rotateZ(20deg);
  }
  100% {
    transform: rotateZ(-120deg);
  }
}
