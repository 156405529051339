.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--header-bg-color, #ffffff);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.logo {
  width: auto;
  height: 35px;
  padding-left: 7%;
  margin-top: 6px;
  margin-bottom: 6px;
}

.logo:hover {
  cursor: pointer;
}

.desktop-nav {
  display: flex;
  margin-right: 16px;
}

.ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.li {
  margin-right: 32px;
}

.desktop-nav a {
  text-decoration: none;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  text-align: center;
  color: #29292e;
  font-family: "Recline Regular";
}

.accountIcon {
  position: relative;
  top: -4px;
}

.login {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  text-align: center;
  color: #2c2c2c;
  margin-top: 10.4px;
  font-family: "Recline Regular";
  padding-right: 7%;
}

.divider-submenu {
  border: 1.28px solid rgba(229, 229, 229, 0.5);
  margin: 0px 40px;
}

@media (min-width: 1000px) {
  .desktop-nav {
    margin-top: 6px;
  }
}

@media (max-width: 1000px) {
  .login {
    padding-right: 2%;
  }
  .li {
    margin-right: 20.8px;
  }
  .logo {
    padding-left: 0%;
    height: 30px;
    margin-left: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .desktop-nav {
    margin-top: 12px;
  }
}

@media (max-width: 768px) {
  .divider-submenu {
    margin: 0px 160px;
  }

  .accountIcon {
    position: relative;
    top: 3px;
  }

  .logo {
    padding-right: 110px;
    padding-left: 25px;
  }
  .desktop-nav {
    margin-right: 0px;
  }

  .desktop-nav .mobile-nav a {
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #2c2c2c;
    font-family: "Recline Regular";
  }

  .desktop-nav .mobile-nav {
    position: absolute;
    right: 8px;
    top: 16px;
  }

  .desktop-nav .mobile-nav .active {
    font-family: "Recline Medium";
    font-weight: 400;
    border-bottom: 2px solid #29292e;
    padding: 5px 10px;
  }
}

@media (max-width: 768px) {
  .login {
    display: none;
  }
  .logo {
    padding-right: 100px;
    padding-left: 45px;
  }
  .desktop-nav {
    margin-right: 16px;
  }
}

.hamburger-line {
  display: block;
  width: 20px;
  height: 2px;
  margin-bottom: 7px;
  background-color: #29292e;
}

.menu-toggle {
  background-color: #ffffff;
  margin-top: 0;
  /* margin: 4.8px 0px; */
}

.mobile-ul {
  position: absolute;
  right: 0;
  padding: 5%;
  background: #fff;
  width: 93vw;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
}

.mobile-ul li {
  font-family: "Recline Regular";
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #2c2c2c;
  list-style: none !important;
  margin-bottom: 16px;
}

.desktop-nav .active {
  font-family: "Recline Medium";
  font-weight: 500;
  border-bottom: 2px solid #29292e;
  padding: 5px 10px;
}

.header-hidden {
  display: none;
}

.submenu {
  position: absolute;
  left: auto;
  padding: 16px;
  width: max-content;
  min-width: 10%;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  margin-top: 0px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.submenuuser {
  position: absolute;
  list-style: none;
  top: 64px;
  right: 64px;
  left: auto;
  padding: 16px 0 16px 0;
  width: max-content;
  min-width: 10%;
  visibility: visible;
  opacity: 1;
  z-index: 9999;
  margin-top: 0px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.submenuuser li {
  padding-left: 16px;
  padding-right: 16px;
}

.colored-header .submenuuser a {
  color: #29292e;
}

.colored-header .submenuuser img {
  filter: none;
}

.submenu li {
  font-family: "Recline Regular";
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #29292e;
  list-style: none !important;
  padding: 3.2px 0px;
}

.submenu li a {
  text-decoration: none;
}

.submenu-mobile li {
  font-family: "Recline Regular";
  font-weight: 400;
  font-size: 12.8px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #29292e;
  list-style: none !important;
  padding: 3.2px 0px;
  margin-bottom: 0;
}

.submenu-mobile li a {
  text-decoration: none;
}

.products-container {
  max-height: 100px;
  overflow-y: auto;
}

.header-profile-icon {
  position: relative;
  top: -24px;
  right: 16px;
}

.header-background-letters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  font-family: "Recline Regular";
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0em;
  position: absolute;
  right: 28px;
  top: 8.8px;
  z-index: 8;
  font-family: "Recline Regular";
  font-weight: 500;
  font-size: 18px;
  border-radius: 50%;
  background-color: #bad0a2;
}
.header-background-letters p {
  margin-top: 2px;
}
.header-background-letters-chat {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 0px;
  color: #ffff;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50%;
  font-family: "Recline Regular";
  font-size: 18px;
  font-weight: 500;
  /* line-height: 56px; */
  letter-spacing: 0em;
  position: relative;
  /* right: 28px;*/
  top: 27.2px;
  left: 1.6px;
  z-index: 8;
  font-family: "Recline Regular";
  /* font-weight: 500;
  font-size: 18px;*/
  /* line-height: 68px;  */
}
.header-profile-username {
  font-family: "Recline Regular";
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #29292e;
  right: 52px;
  position: absolute;
  top: 32px;
}
